nav{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    background-color: #fff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.116);
}
nav ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
nav ul li{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
nav ul li a{
    color: #000;
    font-size: 1.6rem;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
nav ul li a.active{
    color: var(--green);
    background-color: #D7D7D7;
}
