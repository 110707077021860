section.login{
    background: url('../../asset/loginbg.jpg');
    width: 100%;
    height: 100vh;
    text-align: center;
    color: #fff;
}
section.login div.welcometext{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}
section.login div.welcometext p{
    font-weight: 500;
}
section.login form{
    width: 100%;
    height: auto;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
}
section.login form div.input{
    width: 100%;
    height: 80%;
}
section.login form div.input div{
    padding: 0 1rem;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
}
section.login form div.input div .emailfield{
    text-transform: lowercase;
}
section.login form div.input div span{
    color: red;
}
section.login form div.input div input{
    background-color: transparent;
    color: #fff;
    outline: none;
    border: none;
    border-bottom: 2px solid #fff;
    width: 100%;
    height: 50px;
    font-size: 1.2rem;
}
section.login form div.input div .same{
    font-size: 1.1rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: rgb(126, 21, 21);
    background-color: #000;
}
section.login form .button{
    width: 100%;
    min-height: 20%;
    max-height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
section.login form .button .btn{
    width: 80%;
    height: 55px;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.836);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
}
section.login form .button .btn:first-child{
    margin-bottom: 1rem;
}
section.login form .button p{
    font-size: .9rem;
}
section.login form .button p .pa{
    text-transform: uppercase;
    color:#FFD600;
}