section.logout{
    position: absolute;
    top: 25px;
    right: 15px;
    width: 60%;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25), 0px -4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 100;
}
section.logout div {
    padding: 0 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items:  center;
}
section.logout div button {
    color: #000;
    background-color: transparent;
    border: none;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.logout div .logout{
    font-size: 1.3rem;
}