section.spinner{
    width: 100%;
    height: 100vh;
    background-color: #0000008c;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.spinner div{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #0000008c;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.spinner div .spin{
    font-size: 3rem;
    color: #fff;
    animation: spinner 1s ease-in-out infinite;
}
@keyframes spinner {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}