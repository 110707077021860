section.detail-link{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
section.detail-link .back{
    align-self: flex-start;
    width: 100px;
    height: 50px;
    border-radius: 5px;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
section.detail-link .img--container{
    width: 300px;
    height: 300px;
}
section.detail-link .img--container img{
    width: 100%;
    height: 100%;
}
section.detail-link p{
    font-size: 1.1rem;
    font-weight: 600;
}
section.detail-link .btn{
    width: 280px;
    height: 50px;
    border-radius: 5px;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
}